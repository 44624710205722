export const pt = {
  lang: "pt",
  messages: {
    "Create your {{appName}} account": "Crie a sua conta {{appName}}",
    "Text too small.": "Texto demasiado pequeno",
    "Invalid text.": "Texto inválido",
    "The emails don't match": "Os emails não correspondem",
    "Invalid email address": "Email inválido",
    "Email address too small": "Email demasiado pequeno",
    "The passwords don't match": "As passwords não correspondem",
    "The password must contain 1 number 1 lower case letter and 1 upper case leter (may have special chars, spaces not allowed)":
      "A password deve conter 1 número 1 letra minúscula e 1 letra maiúscula (pode ter caracteres especiais, espaços não são permitidos)",
    "Password must be at least 8 characters long.": "A password deve ter no mínimo 8 caracteres.",
    "File must be type .pdf, .jpeg, .jpg or .png.": "O ficheiro tem de ser do tipo .pdf, .jpeg, .jpg ou .png.",
    "File size must be less than 5MB.": "O tamanho do ficheiro tem de ser menor que 5MB.",
    "Upload file": "Upload ficheiro",
    "The username must only contain alphanumeric characters.": "Username só pode conter caracteres alfanuméricos ",
    "Username must have at least 4 characters.": "Username tem de ter no mínimo 4 caracteres",
    Username: "Username",
    Password: "Password",
    "New Password": "Nova password",
    "Reset password": "Redefinir password",
    "Your account has been created successfully!": "A sua conta foi criada com sucesso!",
    "Your email has been verified, enter the platform by clicking the button below!": "O seu e-mail foi verificado, entre na plataforma clicando no botão abaixo",
    "The username is already taken, please choose another.": "O nome de usuário já está em uso, escolha outro.",
    "You will receive an email as soon as the account becomes active.":
      "Irá receber um email assim que a sua conta ficar ativa.",
    SEND: "ENVIAR",
    "Finish creating your {{appName}} account": "Finalize criação da sua conta {{appName}}",
    "Thank you for confirming your email address.": "Obrigado por confirmar o seu endereço de email.",
    "Please define your username and your identity verification.":
      "Por favor defina as suas credenciais e a sua verificação de identidade.",
    "Please define your username.": "Por favor defina o seu username.",
    "<strong>Case-sensitive username.</strong><p>If there is already a username equal to the one chosen we will add a number at the end.</p><p>Example: username1</p>":
      "<strong>Username sensível a maiúsculas.</strong><p>Se já existir um username igual ao escolhido iremos adicionar um número no final.</p><p>Exemplo: username1</p>",
    "<strong>The password must be at least 8 characters long, have 1 number, 1 lower case letter and 1 uppercase letter.</strong><p>May have special characters.</p><p>Spaces are not allowed.</p>":
      "<strong>A password deve ter no mínimo 8 caracteres, ter 1 número, 1 letra minúscula e 1 letra maiúscula.</strong><p>Pode ter caracteres especiais.</p><p>Espaços não são permitidos.</p>",
    "{{appName}} account": "Conta {{appName}}",
    Identification: "Identificação",
    "Identification document - front": "Documento de Identificação - frente",
    "Identification document - backside (if not already icluded on the file above)":
      "Documento de Identificação - verso (caso não esteja já incluído no documento acima)",
    Address: "Morada",
    "Post code": "Código postal",
    City: "Cidade",
    "Proof of address": "Comprovativo de morada",
    "<p>Dated less than 3 months.</p>": "<p>Com data inferior a 3 meses.</p>",
    "Registration successful!": "Registro efetuado com sucesso!",
    "Please check your email to confirm your identity.": "Por favor verifique o seu email para confirmar a sua identidade",
    '(Please check in the "Spam" box)': '(Veja também na caixa de "Spam", por favor)',
    Name: "Nome",
    Surname: "Sobrenome",
    Country: "País",
    Currency: "Moeda",
    "Expected monthly volume": "Volume mensal previsto",
    "Interface page for register on <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> betting platform":
      "Página de interface para registro na plataforma de apostas <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a>.",
    "This is the page for making an account on {{appName}}, if you have already done this step then go to <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> or click 'Login' on <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.":
      "Esta é a página para criação de uma conta {{appName}}, se já tiver feito este passo vá a <a href='{{siteLoginUrl}}' target='_blank'>{{siteLogin}}</a> ou clique 'Entrar' em <a href='{{logoLinkUrl}}' target='_blank'>{{logoLink}}</a>.",
    "It only takes a minute": "Demora apenas um minuto",
    "We detected that you already have a {{appName}} account and we now resend an email with your details.":
      "Detectámos que você já tem uma conta e reenviamos agora um email com os seus dados.",
    'Please check your email to confirm your identity (Please check in the "Spam" box)':
      'Por favor verifique o seu email (Veja também na pasta de "Spam", por favor).',
    "Password updated successfully!": "Password atualizada com sucesso!",
    "Login at <a href='{{siteLoginUrl}}'>{{siteLogin}}</a> and start betting!":
      "Aceda a <a href='{{siteLoginUrl}}'>{{siteLogin}}</a> e comece a apostar!",
    "This username does not match the current password reset token.":
      "Este username não corresponde ao token atual de redefinição de password.",
    "Welcome to {{domainName}} betting platform. The most respected Asian bookmakers, where winners are welcome.":
      "Bem-vindo à plataforma de apostas {{domainName}}. As casas de apostas asiáticas mais respeitadas, onde os vencedores são bem-vindos.",
    "Register a new account on {{name}}": "Registre uma nova conta no {{name}}",
    "id-card-name-EN": "Nº de Passaporte ou ID Local",
    CPF: "CPF",
    "Phone Number": "Número WhatsApp",
    "Number between 5 and 15 digits, can start with '+'": "Número entre 5 e 15 dígitos, pode começar com '+'",
    "Invalid Pattern": "Padrão inválido",
    "Invalid CPF number": "Número de CPF inválido",
    "You must be 18 or older": "Você deve ter 18 anos ou mais",
    "Birthdate": "Data de nascimento",
    "Invalid date": "Data inválida",
    "Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Nota: neste documento tem de ser visível o nome completo, foto, data de nascimento, e número CPF.",
    "Accepted: National Id or Drivers license.<br>Note: in this document the full name, photo, date of birth, and National ID or Passport number must be visible.":
      "Documento aceite: CPF ou CNH.<br>Nota: neste documento tem de ser visível o nome completo, foto, data de nascimento, e número CPF.",
    "Confirm Password": "Confirmar Password",
    "Confirm Email": "Confirmar Email",
    "(DD-MM-YYYY)": "(DD-MM-AAAA)",
    "Day": "Dia",
    "Month": "Mês",
    "Year": "Ano",
    "I confirm I have read and I agree to the <a id='{{TermsAndConditions}}' href='#'>Terms and Conditions</a> and <a id='{{PrivacyPolicy}}' href='#'>Privacy Policy</a> and confirm I am 18 years of age or over.":
      "Confirmo que li e concordo com os <a id='{{TermsAndConditions}}' href='#'>Termos e Condições</a> e a <a id='{{PrivacyPolicy}}' href='#'>Política de Privacidade</a> e que tenho 18 ou mais anos.",
    "You need to confirm Terms and Conditions and Privacy Policy": "Você precisa de confirmar os Termos e Condições e a Política de Privacidade",
    "Terms And Conditions": "Termos e Condições",
    "Value already in use": "Valor já se encontra em uso"
  },
};
